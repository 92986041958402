import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/maniac-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1980 slasher movie, Maniac"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 04</h1>
                    <h2>Maniac</h2>
                    <h3>April 30, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Bill Lustig // <b>Starring:</b> Joe Spinell &amp; Caroline Munroe</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bryan-jude-white/embed/episodes/04-Maniac-e21l6vg" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe Horror Podcastepisode 4, Maniac"></iframe>
                        </div>
                        <p><Link to="/transcripts/maniac">Read the episode transcript</Link></p>
                        <p>This week Bryan and Dave take a look at William Lustig's 1980 roughie, Maniac, starring Joe Spinell and Caroline Munro. Bryan also goes off on a long tangent about the time he met Tom Savini and how it haunted Savini for years.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/the-sentinel">Next Episode</Link></li>
                        <li><Link to="/episodes/pieces">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)